<template>
  <default-layout :searchFunction="loadData" searchParamString="q">
    <v-container fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <order-list
        :items="orders"
        :meta="meta"
        :permissions="permissions"
        @changePage="changePage"
        @changeDateFilter="changeDateFilter"
        @changeStatusFilter="changeStatusFilter"
        @changeTypeFilter="changeTypeFilter"
        @changeSalesFilter="changeSalesFilter"
      ></order-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const OrderList = () => import('@/components/transaction-management/order/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    OrderList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
      minDateFilter: null,
      maxDateFilter: null,
      statusFilter: null,
      typeFilter: null,
      salesFilter: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.order.permissions,
      orders: (state) => state.order.orders,
      meta: (state) => state.order.meta,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('order/RESET_DEFAULT');
      const response = await this.$store.dispatch('order/getInitData', query);
      await this.$store.dispatch('order/checkRefundAllowed');
      console.log('CHECK ORDER INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('order/getData', query);
        console.log('GET ORDER DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.min_date = this.minDateFilter;
      query.max_date = this.maxDateFilter;
      query.status_filter = this.statusFilter;
      query.type_filter = this.typeFilter;
      query.sales_filter = this.salesFilter;
      query.page = page;
      await this.loadData(query);
    },
    async changeDateFilter(val) {
      let query = this.$route.query;
      query.page = 1;

      this.minDateFilter = query.min_date = val ? val[0] : null;
      this.maxDateFilter = query.max_date = val ? val[1] : null;
      await this.loadData(query);
    },
    async changeStatusFilter(val) {
      let query = this.$route.query;
      query.page = 1;
      this.statusFilter = query.status_filter = val;
      await this.loadData(query);
    },
    async changeTypeFilter(val) {
      let query = this.$route.query;
      query.page = 1;
      this.typeFilter = query.type_filter = val;
      await this.loadData(query);
    },
    async changeSalesFilter(val) {
      let query = this.$route.query;
      query.page = 1;
      if (val === 'Diri Sendiri') {
        this.salesFilter = query.sales_filter = true;
      }
      // else if (val === 'Selain Diri Sendiri') {
      //   this.salesFilter = query.sales_filter = false;
      // }
      else {
        this.salesFilter = query.sales_filter = val;
      }
      await this.loadData(query);
    },
  },
};
</script>
